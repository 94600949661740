    import React from 'react'
    import Person3 from '../assets/Person3.png';
    import Person1 from '../assets/Person1.png';
    import Person2 from '../assets/Person2.png';
    import Person4 from '../assets/Person4.png';

    const Team = () => {
    return (
        <div id="team">
        <div class="py-20 bg-black">
        <div class="container mx-auto px-6 md:px-12 xl:px-16">
            <div class="mb-16 text-center">
                <h2 class="mb-4 text-center text-2xl text-white font-bold md:text-4xl">Meet Our Team</h2>
                <p class="text-gray-400 lg:w-8/12 lg:mx-auto">Introducing Our Expert Team: 25 Years of Knowledge, Cutting-Edge Tech, and Exceptional Project Management Skills for Seamless Results.</p>
            </div>
            <div class="grid gap-16 items-center md:grid-cols-4">
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-80" 
                        src={Person1} alt="person1" loading="lazy" width="640" height="805"/>
                    <div>
                        <h4 class="text-xl mt-8 text-white font-inter font-semibold">Mr. Surendra Kumar Gaur 
    </h4>
                        <span class="block text-sm text-gray-500 mt-3">Engineer, Chief Advisor</span>
                    </div>
                </div>
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80" 
                        src={Person2} alt="person2" loading="lazy" width="1000" height="667"/>
                    <div>
                        <h4 class="text-xl mt-8 text-white font-inter font-semibold">Mrs. Jaya Gaur 
    </h4>
                        <span class="block text-sm text-gray-500 mt-3">Partner</span>
                    </div>
                </div>
                
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-80" 
                        src={Person3} alt="person3" loading="lazy" width="1000" height="667"/>
                    <div>
                        <h4 class="text-xl mt-8 text-white font-inter font-semibold">Mr. Hritvik Gaur</h4>
                        <span class="block mt-3 text-sm text-gray-500">Partner</span>
                    </div>
                </div>
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-80" 
                        src={Person4} alt="person3" loading="lazy" width="1000" height="667"/>
                    <div>
                        <h4 class="text-xl mt-8 text-white font-inter font-semibold">Mr. Navaditya Gaur</h4>
                        <span class="block mt-3 text-sm text-gray-500">Senior Advisor</span>
                    </div>
                </div>
            </div>

            

            
        </div>
    </div>
        </div>
    )
    }

    export default Team;

    <div class="py-18 bg-gray-50">
        <div class="container mx-auto px-4 md:px-12 xl:px-32">
            <div class="mb-16 text-center">
                <h2 class="mb-4 text-center text-2xl text-gray-900 font-bold md:text-4xl">Tailus blocks leadership</h2>
                <p class="text-gray-600 lg:w-8/12 lg:mx-auto">Tailus prides itself not only on award-winning technology, but also on the talent of its people of some of the brightest minds and most experienced executives in business.</p>
            </div>
            <div class="grid gap-12 items-center md:grid-cols-3">
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64" 
                        src="https://tailus.io/sources/blocks/classic/preview/images/woman1.jpg" alt="woman" loading="lazy" width="640" height="805"/>
                    <div>
                        <h4 class="text-2xl">Hentoni Doe</h4>
                        <span class="block text-sm text-gray-500">CEO-Founder</span>
                    </div>
                </div>
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80" 
                        src="https://tailus.io/sources/blocks/classic/preview/images/man.jpg" alt="man" loading="lazy" width="1000" height="667"/>
                    <div>
                        <h4 class="text-2xl">Jonathan Doe</h4>
                        <span class="block text-sm text-gray-500">Chief Technical Officer</span>
                    </div>
                </div>
                <div class="space-y-4 text-center">
                    <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64" 
                        src="https://tailus.io/sources/blocks/classic/preview/images/woman.jpg" alt="woman" loading="lazy" width="1000" height="667"/>
                    <div>
                        <h4 class="text-2xl">Anabelle Doe</h4>
                        <span class="block text-sm text-gray-500">Chief Operations Officer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>