import React from 'react'

const TermsPage = () => {
  return (
    <div className='text-white text-center'>
      <header class="header">
      <div class="container-fluid">
        <div class="row">
          <div class="col col-xs-10 col-xs-offset-1 col-sm-5 col-sm-offset-1">
            <a href="/">
              <img
                class="img-responsive logo"
                src="images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-logo.png"
                srcset="
                  images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-logo@2x.png 2x,
                  images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-logo@3x.png 3x
                "
                draggable="false"
              />
            </a>
          </div>
          <div class="col col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-1">
            <h2 class="title">Easy, accessible, team oriented</h2>
            <p class="subtitle">
              Manage your SKG Real Estate Developers LLPprojects from the palm of your hand.
              Anytime, anywhere.
            </p>
            <a
              href="http://itunes.apple.com/us/app/SKG Real Estate Developers LLP SKG Real Estate Developers LLP/id1245190471"
              target="_blank"
            >
              <img
                class="store-button"
                src="images/appstore-button.png"
                srcset="
                  images/appstore-button@2x.png 2x,
                  images/appstore-button@3x.png 3x
                "
                draggable="false"
              />
            </a>
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid">
      <div class="row terms-and-conditions-section">
        <div class="col-xs-12 text-center">
          <h1 class="title">Terms and Conditions</h1>
        </div>

        <div
          class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2"
        >
          <div class="content">
            <p>
              SKG Real Estate Developers LLP SKG Real Estate Developers LLP, SRL ("SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India") operates SKG Real Estate Developers LLP(as defined below), your
              use of SKG Real Estate Developers LLPis governed by these Terms of Service
              (“Terms”), and your use of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              constitutes your acceptance of the Terms.
            </p>
            <p>
              "SKG Real Estate Developers LLP SKG Real Estate Developers LLP" (the "Service") means SKG Real Estate Developers LLP
              After Indiaproject management solution made available at
              <a href="https://trySKG Real Estate Developers LLP SKG Real Estate Developers LLP.com"
                >https://trySKG Real Estate Developers LLP SKG Real Estate Developers LLP.com</a
              >
              (the “Site”) and any other software, sites, and services offered
              by SKG Real Estate Developers LLPin connection therewith but does not include
              any sites, services, products, or offerings governed by other
              terms of use, or, other agreements between you and SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP. "Customer Data" means any data you make
              available to SKG Real Estate Developers LLPor SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              from your SKG Real Estate Developers LLPaccount for the purpose of using SKG Real Estate Developers LLP
              After India SKG Real Estate Developers LLP. "Content" means all content
              generated by SKG Real Estate Developers LLP SKG Real Estate Developers LLP on your behalf
              (including metric data) and does not include Customer Data.
            </p>
            <p>
              We will endeavor to provide you with notice about major changes to
              the Terms or SKG Real Estate Developers LLPand other changes (via email to the
              address on your account), but we make no guarantees. Other changes
              could be, without limitation, changes to payment policies,
              security patches, added or removed functionality, and other
              enhancements or restrictions to SKG Real Estate Developers LLP SKG Real Estate Developers LLP.
              Your use of SKG Real Estate Developers LLP SKG Real Estate Developers LLP after the Terms have
              changed constitutes your acceptance of the Terms as changed. You
              may reject changes to the Terms by terminating your subscription
              in accordance with the "Cancellation and Termination" section of
              these Terms of Service. SKG Real Estate Developers LLP SKG Real Estate Developers LLP shall
              not be liable to you or to any third party for any change to SKG Real Estate Developers LLP
              After India SKG Real Estate Developers LLP.
            </p>
            <p>
              Please use SKG Real Estate Developers LLPresponsibly and in compliance with
              these Terms. If you don't, we reserve the right to terminate your
              subscription in accordance with the "Cancellation and Termination"
              section of these Terms of Service.
            </p>

            <h2 class="section-title">
              1. Use of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
            </h2>
            <p>
              To use SKG Real Estate Developers LLP SKG Real Estate Developers LLP, you must (i) have a SKG Real Estate Developers LLP
              After Indiaaccount (ii) authorize SKG Real Estate Developers LLPto access your
              Customer Data to provide SKG Real Estate Developers LLP SKG Real Estate Developers LLP, (iii)
              provide accurate SKG Real Estate Developers LLPaccount information any time you
              register to use SKG Real Estate Developers LLP SKG Real Estate Developers LLP, (iv) provide
              accurate SKG Real Estate Developers LLPaccount information when you register to
              use SKG Real Estate Developers LLP SKG Real Estate Developers LLP, and (v) be a human over the
              age of 13. Uses by automated methods are not permitted. You grant
              SKG Real Estate Developers LLPa non-exclusive, royalty-free, and worldwide
              license to use, store, and copy your Customer Data in order to
              provide SKG Real Estate Developers LLP SKG Real Estate Developers LLP.
            </p>
            <p>
              You are responsible for the security of your passwords and for any
              use of your account. You may not allow multiple people to use the
              same account or otherwise access SKG Real Estate Developers LLPin a manner
              intended to avoid incurring fees.
            </p>
            <p>
              You must comply with all applicable SKG Real Estate Developers LLPpolicies and
              terms of service. SKG Real Estate Developers LLPis not responsible for any acts
              or omissions of SKG Real Estate Developers LLPwhether related to your account or
              otherwise.
            </p>
            <p>
              SKG Real Estate Developers LLPreserves the right to enforce quotas and usage
              limits (to any resources, including the API) at its sole
              discretion, with or without notice, which may result in SKG Real Estate Developers LLP
              Indiadisabling or throttling your usage of the Service for any
              amount of time.
            </p>
            <p>
              You are responsible for obtaining access to SKG Real Estate Developers LLPand
              that access may involve third party fees (such as Internet service
              provider or airtime charges). You are responsible for those fees.
              In addition, you must provide and are responsible for all
              equipment necessary to access the Site.
            </p>
            <p>You may not use :</p>
            <ul>
              <li>
                for any illegal purpose, in any manner inconsistent with
                applicable laws, regulations, or ordinances, or to submit, post,
                upload, or otherwise transmit any Customer Data or Content that
                is unlawful, defamatory, libelous, invasive of another's
                privacy, abusive, threatening, harassing, vulgar, obscene,
                indecent or otherwise objectionable;
              </li>
              <li>
                to submit, post, upload or otherwise transmit any Customer Data
                or Content that infringes or otherwise violates the rights of
                any third party, including without limitation privacy rights,
                fiduciary rights and proprietary rights;
              </li>
              <li>
                to submit, post, upload or otherwise transmit Customer Data or
                Content that contains viruses, corrupted files, or any other
                similar software or programs that may damage the operation of
                SKG Real Estate Developers LLPor another person's computer; or
              </li>
              <li>
                if you are a person barred from receiving SKG Real Estate Developers LLPunder
                the laws of the United States or other countries, including the
                country in which you are resident or from which you use SKG Real Estate Developers LLP
                After India SKG Real Estate Developers LLP.
              </li>
            </ul>

            <h2 class="section-title">2. Cancellation and Termination</h2>
            <p>
              To cancel your subscription, account, and access to SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP, send us an email at
              <a
                href="mailto:hi@trySKG Real Estate Developers LLP SKG Real Estate Developers LLP.com?Subject=Cancel+Subscription"
                >hi@trySKG Real Estate Developers LLP SKG Real Estate Developers LLP.com</a
              >
              instructing us to do so or cancel via the functionality on the
              Waffle website. If you paid for your subscription, SKG Real Estate Developers LLP
              Indiais not obligated to provide any refunds if you cancel your
              subscription or if SKG Real Estate Developers LLPterminates your subscription
              because you breached these Terms. If your subscription is
              cancelled or terminated in accordance with the foregoing before
              the end of the current paid-up month, your cancellation will take
              effect immediately and you will not be charged again. All of your
              Customer Data and Content will, within a reasonable amount of time
              to be determined solely by SKG Real Estate Developers LLP SKG Real Estate Developers LLP, be
              deleted from SKG Real Estate Developers LLP SKG Real Estate Developers LLP upon cancellation.
            </p>
            <p>
              You agree that SKG Real Estate Developers LLP SKG Real Estate Developers LLP, in its sole
              discretion and for any or no reason, may terminate or suspend your
              account. You agree that any termination of your access to the
              Service may be without prior notice, and you agree that SKG Real Estate Developers LLP
              Indiawill not be liable to you or any third party for such
              termination. If you paid for your subscription, and SKG Real Estate Developers LLP
              Indiadid not terminate or suspend your account due to your breach
              of these Terms, SKG Real Estate Developers LLPwill provide a pro-rata refund of
              unused subscription fees if it terminates or suspends your account
              in accordance with this paragraph.
            </p>

            <h2 class="section-title">3. Customer Data</h2>
            <p>
              SKG Real Estate Developers LLPclaims no ownership or control over any Customer
              Data. Except for the license expressly granted by you to SKG Real Estate Developers LLP
              After India SKG Real Estate Developers LLP to provide SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India, you retain all right, title, and interest in and to
              Customer Data and you are responsible for protecting those rights
              and interests, as appropriate. Notwithstanding the foregoing, you
              grant to SKG Real Estate Developers LLPfor its use a worldwide, non-exclusive,
              royalty-free license to aggregate or compile Customer Data with
              customer data of other users of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              so long as such aggregation or compilation omits any data that
              would enable the identification of you (“Aggregated Data”). SKG Real Estate Developers LLP
              After Indiashall have a worldwide, perpetual, royalty-free license
              to use, modify, distribute and create derivative works based on
              such Aggregated Data, and as between you and SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India, SKG Real Estate Developers LLPshall own all compilations of the
              Aggregated Data, including all reports, statistics or analyses
              created or derived therefrom.
            </p>
            <p>
              You understand that projects in SKG Real Estate Developers LLPwill display
              Customer Data to you and any collaborators that you designate for
              that project. You are solely responsible for any collaborators and
              third-parties (including third-party services) that you allow to
              view Customer Data and entrust them at your own risk.
            </p>
            <p>
              You are solely responsible for configuring SKG Real Estate Developers LLPto
              allow appropriate access to your Customer Data. SKG Real Estate Developers LLPis
              not responsible if you fail to configure, or misconfigure, your
              project and inadvertently allow unauthorized parties to view any
              Customer Data.
            </p>

            <h2 class="section-title">4. Ideas and Feedback</h2>
            <p>
              As part of your use of SKG Real Estate Developers LLP SKG Real Estate Developers LLP, you may
              choose, or we may invite you, to submit comments, feedback or
              ideas about SKG Real Estate Developers LLP SKG Real Estate Developers LLP, including but not
              limited to ideas about improving SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              or our other offerings ("Ideas"). You hereby grant SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP a perpetual, royalty-free, unlimited,
              worldwide license to use and/or incorporate such Ideas into any
              SKG Real Estate Developers LLP SKG Real Estate Developers LLP offering (including SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP) at any time in SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              India’s sole discretion. Ideas you submit will not be afforded any
              confidential treatment by SKG Real Estate Developers LLP SKG Real Estate Developers LLP,
              regardless of whether you request such treatment or otherwise
              designate Ideas as proprietary or confidential.
            </p>

            <h2 class="section-title">5. External Sites and Content</h2>
            <p>
              The SKG Real Estate Developers LLPmay contain links to other websites or content
              (“Third Party Sites”). SKG Real Estate Developers LLPdoes not endorse, sanction
              or verify the accuracy or ownership of the information contained
              in/on any Third Party Site or any products or services advertised
              on Third Party Sites. If you decide to leave the Site and navigate
              to Third Party Sites, or install any software or download content
              from any such Third Party Sites, you do so at your own risk. Once
              you access a Third Party Site through a link on our Site, you may
              be subject to the terms and conditions of such Third Party Site.
              You should review the applicable policies, including privacy and
              data gathering practices, of any Third Party Site to which you
              navigate from the Site, or relating to any software you use or
              install from a Third Party Site, including any applicable license
              agreement. Concerns regarding a Third Party Site should be
              directed to the Third Party Site itself. SKG Real Estate Developers LLPbears no
              responsibility for any action associated with any Third Party
              Site.
            </p>

            <h2 class="section-title">6. License and Restrictions</h2>
            <p>
              Subject to and conditioned upon your agreement to and compliance
              with these Terms, we will make SKG Real Estate Developers LLPavailable to you on
              a subscription basis solely for your own use and not for resale or
              to provide services to any third party.
            </p>
            <p>
              Excluding Third Party Sites and their related content, all of the
              content available on or through SKG Real Estate Developers LLP SKG Real Estate Developers LLP,
              including without limitation, text, photographs, graphics, logos,
              trade/service marks, and/or audiovisual content, but expressly
              excluding Customer Data, is owned and/or controlled by SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP, its licensors or SKG Real Estate Developers LLPusers and
              is protected, as applicable, by copyright, trademark, trade dress,
              patent, and trade secret laws, other proprietary rights, and
              international treaties. You acknowledge that SKG Real Estate Developers LLPand
              any underlying technology or software used in connection with SKG Real Estate Developers LLP
              After Indiacontain our proprietary information.
            </p>
            <p>
              You will not, nor will you attempt to (or allow anyone else to or
              attempt to):
            </p>
            <ul>
              <li>
                copy, modify, create a derivative work of, rSKG Real Estate Developers LLPse engineer,
                decompile or otherwise attempt to extract the source code of
                SKG Real Estate Developers LLP SKG Real Estate Developers LLP or any part thereof;
              </li>
              <li>
                attempt to disable or circumvent any security or verification
                mechanism used by SKG Real Estate Developers LLP SKG Real Estate Developers LLP;
              </li>
              <li>
                use SKG Real Estate Developers LLPin any manner, or otherwise engage in any
                activity, that could damage, disable, overburden or impair our
                servers or networks, or interfere with or disrupt SKG Real Estate Developers LLP
                India SKG Real Estate Developers LLP or any other users' use or enjoyment of
                SKG Real Estate Developers LLP SKG Real Estate Developers LLP.
              </li>
              <li>
                attempt to gain unauthorized access to any of SKG Real Estate Developers LLP
                SKG Real Estate Developers LLP, member accounts, or computer systems or
                networks, through hacking, password mining or any other means.
              </li>
              <li>
                remove any notices of copyright, trademark or other proprietary
                rights contained in/on or accessible through SKG Real Estate Developers LLPor
                in any content or other material obtained via SKG Real Estate Developers LLP
                SKG Real Estate Developers LLP;
              </li>
              <li>
                use any robot, spider, website search/retrieval application, or
                other automated device, process or means to access, retrieve or
                index any portion of SKG Real Estate Developers LLP SKG Real Estate Developers LLP;
              </li>
              <li>
                reformat or frame any portion of the web pages that are part of
                SKG Real Estate Developers LLP SKG Real Estate Developers LLP;
              </li>
              <li>
                use SKG Real Estate Developers LLPfor commercial purposes not permitted under
                these Terms;
              </li>
              <li>
                in connection with your use of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
                India, make unauthorized use of others’ SKG Real Estate Developers LLPaccounts
                or Customer Data;
              </li>
              <li>
                create user accounts by automated means or under false or
                fraudulent pretenses;
              </li>
              <li>
                provide or use tracking or monitoring functionality in
                connection with SKG Real Estate Developers LLP SKG Real Estate Developers LLP, including,
                without limitation, to identify other users’ actions or
                activities;
              </li>
              <li>
                impersonate or attempt to impersonate any other person or
                entity, including without limitation SKG Real Estate Developers LLPor any
                employee, contractor or associate of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
                India; or
              </li>
              <li>
                collect or store personal data about other users in connection
                with the prohibited activities described in this paragraph.
              </li>
            </ul>

            <h2 class="section-title">7. Open Source Software</h2>
            <p>
              Open source software licenses for components of SKG Real Estate Developers LLP
              SKG Real Estate Developers LLP released under an open source license constitute
              separate written agreements. To the limited extent that the open
              source software licenses expressly supersede these Terms, the open
              source licenses govern your agreement with SKG Real Estate Developers LLPonly
              for the use of the components of SKG Real Estate Developers LLPreleased under an
              open source license.
            </p>

            <h2 class="section-title">8. Monitoring of Content</h2>
            <p>
              You understand that SKG Real Estate Developers LLPmay access and disclose
              Customer Data or otherwise provide third parties access to it for
              the following reasons:
            </p>
            <ul>
              <li>to send an email message to you upon your request;</li>
              <li>
                to maintain the Site and SKG Real Estate Developers LLPand to develop new and
                useful features and services;
              </li>
              <li>
                to follow a court order, subpoena, complaint or a lawful request
                from governmental authorities;
              </li>
              <li>to enforce these Terms;</li>
              <li>
                to respond to claims that any Customer Data or Content violates
                third party rights;
              </li>
              <li>to respond to your requests for customer service; and</li>
              <li>
                to protect the rights, property, or personal safety of SKG Real Estate Developers LLP
                After India SKG Real Estate Developers LLP, users of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
                After India’s offerings, including without limitation SKG Real Estate Developers LLP
                India SKG Real Estate Developers LLP, and the public.
              </li>
            </ul>

            <h2 class="section-title">9. Our Copyright Dispute Policy</h2>
            <p>
              SKG Real Estate Developers LLPrespects the intellectual property of others and
              requires that our users do the same. If you believe that material
              or content residing on or accessible through SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India infringes a copyright, please send a notice of
              copyright infringement containing the following information to the
              Designated Copyright Agent listed below:
            </p>
            <ul>
              <li>
                identification of the copyrighted work claimed to have been
                infringed, or, if multiple copyrighted works are covered by a
                single notification, a representative list of such works;
              </li>
              <li>
                identification of the claimed infringing material and
                information reasonably sufficient to permit us to locate the
                material on SKG Real Estate Developers LLP(providing the URL(s) of the claimed
                infringing material satisfies this requirement);
              </li>
              <li>
                information reasonably sufficient to permit us to contact you,
                such as an address, telephone number, and an email address;
              </li>
              <li>
                a statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law;
              </li>
              <li>
                a statement by you, made under penalty of perjury, that the
                above information in your notification is accurate and that you
                are the copyright owner or are authorized to act on the
                copyright owner's behalf; and
              </li>
              <li>your physical or electronic signature.</li>
            </ul>
            <p>
              Our Designated Copyright Agent for notification of claimed
              infringement can be reached by email at: hi@trySKG Real Estate Developers LLP
              SKG Real Estate Developers LLP.com or at the following postal address:
            </p>
            <p>
              Attn: Legal/Urgent, SKG Real Estate Developers LLP SKG Real Estate Developers LLP/SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP, 1011 Cassinoni, Montevideo, Uruguay
            </p>
            <p>
              <b>Disclaimer of Warranties:</b>
            </p>
            <p>
              IF YOU ACCESS SKG Real Estate Developers LLP SKG Real Estate Developers LLP, YOU DO SO AT YOUR
              OWN RISK. WE PROVIDE SKG Real Estate Developers LLP“AS IS,” “WITH ALL FAULTS”
              AND “AS AVAILABLE.” WE MAKE NO EXPRESS OR IMPLIED WARRANTIES OR
              GUARANTEES ABOUT SKG Real Estate Developers LLP SKG Real Estate Developers LLP. TO THE MAXIMUM
              EXTENT PERMITTED BY LAW, WE HEREBY DISCLAIM ALL SUCH WARRANTIES,
              INCLUDING ALL STATUTORY WARRANTIES, WITH RESPECT TO SKG Real Estate Developers LLP
              India SKG Real Estate Developers LLP, INCLUDING WITHOUT LIMITATION ANY
              WARRANTIES THAT SKG Real Estate Developers LLPIS MERCHANTABLE, OF SATISFACTORY
              QUALITY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED, OR
              NON-INFRINGING. WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY BE
              OBTAINED FROM THE USE OF SKG Real Estate Developers LLPWILL BE EFFECTIVE,
              RELIABLE OR ACCURATE OR WILL MEET YOUR REQUIREMENTS. WE DO NOT
              GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE SKG Real Estate Developers LLP
              India(EITHER DIRECTLY OR THROUGH THIRD-PARTY NETWORKS) AT TIMES OR
              LOCATIONS OF YOUR CHOOSING. WE ARE NOT RESPONSIBLE FOR THE
              ACCURACY, RELIABILITY, TIMELINESS OR COMPLETENESS OF INFORMATION
              PROVIDED BY ANY OTHER USERS OF SKG Real Estate Developers LLPOR ANY OTHER DATA
              OR INFORMATION PROVIDED OR RECEIVED THROUGH SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India. EXCEPT AS EXPRESSLY SET FORTH HEREIN, SKG Real Estate Developers LLP
              IndiaMAKES NO WARRANTIES ABOUT THE INFORMATION SYSTEMS, SOFTWARE
              AND FUNCTIONS MADE ACCESSIBLE BY OR THROUGH SKG Real Estate Developers LLPOR ANY
              SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE
              INFORMATION. SKG Real Estate Developers LLPDOES NOT WARRANT THAT SKG Real Estate Developers LLP
              IndiaWILL OPERATE ERROR-FREE, THAT ERRORS IN THE SERVICE WILL BE
              FIXED, THAT LOSS OF DATA WILL NOT OCCUR, OR THAT SKG Real Estate Developers LLP
              IndiaIS FREE OF COMPUTER VIRUSES, CONTAMINANTS OR OTHER HARMFUL
              ITEMS. UNDER NO CIRCUMSTANCES WILL SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              India, ANY OF OUR AFFILIATES, DISTRIBUTORS, PARTNERS, LICENSORS,
              AND/OR ANY OF OUR OR THEIR DIRECTORS, OFFICERS, EMPLOYEES,
              CONSULTANTS, AGENTS, OR OTHER REPRESENTATIVES BE LIABLE FOR ANY
              LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED
              THROUGH THE SERVICE.
            </p>

            <h2 class="section-title">10. Limitations on Liability</h2>
            <p>
              YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE
              CANCELLATION OF YOUR USE OF SKG Real Estate Developers LLP SKG Real Estate Developers LLP. IN
              NO EVENT SHALL WE BE LIABLE TO YOU (OR TO ANY THIRD PARTY CLAIMING
              UNDER OR THROUGH YOU) FOR ANY DAMAGES WHATSOSKG Real Estate Developers LLP, INCLUDING,
              INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR
              EXEMPLARY DAMAGES OR ANY BODILY INJURY, EMOTIONAL DISTRESS, DEATH
              OR ANY OTHER DAMAGES ARISING FROM YOUR USE OF OR INABILITY TO USE
              SKG Real Estate Developers LLP SKG Real Estate Developers LLP, WHETHER ON-LINE OR OFF-LINE, OR
              OTHERWISE IN CONNECTION WITH SKG Real Estate Developers LLP SKG Real Estate Developers LLP.
              THESE EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA,
              LOSS OF GOODWILL OR BUSINESS REPUTATION, COST OF PROCUREMENT OF
              SUBSTITUTE GOODS OR SERVICES, WORK STOPPAGE, COMPUTER FAILURE OR
              MALFUNCTION, ANY OTHER COMMERCIAL DAMAGES OR LOSSES, OR ANY
              PERSONAL INJURY OR PROPERTY DAMAGES, EVEN IF WE KNEW OR SHOULD
              HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. SKG Real Estate Developers LLP
              SKG Real Estate Developers LLP’S LIABILITY UNDER THESE TERMS SHALL BE LIMITED TO
              THE FEES YOU PAID SKG Real Estate Developers LLPIN THE TWELVE MONTHS PRIOR TO
              THE DATE THE CLAIM AROSE. BECAUSE SOME STATES OR JURISDICTIONS DO
              NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
              CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
              JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED TO THE EXTENT
              PERMITTED BY LAW.
            </p>

            <h2 class="section-title">11. Indemnification</h2>
            <p>
              You agree to hold harmless and indemnify SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India, and its subsidiaries, affiliates, officers, agents,
              employees, advertisers, licensors, suppliers or partners from and
              against any third party claim arising from or in any way related
              to (a) your breach of the Terms, (b) your use of SKG Real Estate Developers LLP
              SKG Real Estate Developers LLP, (c) your violation of applicable laws, rules or
              regulations in connection with SKG Real Estate Developers LLP SKG Real Estate Developers LLP,
              or (d) your Customer Data, including any liability or expense
              arising from all claims, losses, damages (actual and
              consequential), suits, judgments, litigation costs and attorneys'
              fees, of SKG Real Estate Developers LLPy kind and nature. In such a case, SKG Real Estate Developers LLP
              SKG Real Estate Developers LLP will provide you with written notice of such
              claim, suit or action.
            </p>

            <h2 class="section-title">
              12. Choice of Law and Dispute Resolution
            </h2>
            <p>
              These Terms will be governed by and construed in accordance with
              the laws of the State of California without giving effect to
              principles of conflict of laws, and the parties consent to the
              jurisdiction of the federal courts of the State of California.
              Each party irrevocably submits to the jurisdiction and venue of
              any such court in any such action or proceeding. The United
              Nations Convention on Contracts for the International Sale of
              Goods will not apply to These Terms.
            </p>

            <h2 class="section-title">13. General Legal Terms</h2>
            <p>
              These Terms constitute the whole legal agreement between you and
              SKG Real Estate Developers LLPand govern your use of the Service and completely
              replace any prior agreements between you and SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India, written or oral, in relation to SKG Real Estate Developers LLP SKG Real Estate Developers LLP
              After India.
            </p>
            <p>
              If any part of the Terms is held invalid or unenforceable, that
              portion shall be construed in a manner consistent with applicable
              law to reflect, as nearly as possible, the original intentions of
              the parties, and the remaining portions shall remain in full force
              and effect.
            </p>
            <p>
              The failure of SKG Real Estate Developers LLPto exercise or enforce any right or
              provision of the Terms shall not constitute a waiver of such right
              or provision.
            </p>
            <p>
              SKG Real Estate Developers LLPshall not be liable for failing or delaying
              performance of its obligations resulting from any condition beyond
              its reasonable control, including but not limited to, governmental
              action, acts of terrorism, earthquake, fire, flood or other acts
              of God, labor conditions, power failures, and Internet
              disturbances.
            </p>
            <p>
              SKG Real Estate Developers LLPmay assign this contract at any time to any
              parent, subsidiary, or any affiliated company, or as part of the
              sale to, merger with, or other transfer of our company to another
              entity. You may not assign these Terms.
            </p>
            <p>
              These Terms are adapted from the Waffle.io Terms of Service, which
              is an adaption of the CodeClimate Terms of Service, which is an
              adaption of the Heroku Terms of Service, which in turn is an
              adaptation of the Google App Engine Terms of Service. The original
              work has been modified with permission under the Creative Commons
              Attribution 3.0 License. Neither CA, Inc, CodeClimate, LLC,
              Heroku, Inc. nor Google, Inc. is connected with, nor do they
              sponsor or endorse, SKG Real Estate Developers LLPor its use of the work.
            </p>
            <p>Version 1.0, dec 12, 2023</p>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container-fluid">
        <div class="row text-center">
          <h2 class="title">Better organization for a more productive you.</h2>
        </div>
        <div class="row footer-logo-container">
          <div class="col col-xs-12 text-center">
            <img
              src="images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-footer-logo.png"
              srcset="
                images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-footer-logo@2x.png 2x,
                images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-footer-logo@3x.png 3x
              "
              draggable="false"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col col-xs-12 col-sm-4 col-md-3 col-md-offset-1 text-center link-container"
          >
            <a
              class="links"
              href="https://SKG Real Estate Developers LLPSKG Real Estate Developers LLP.com"
              target="_blank"
              >About SKG Real Estate Developers LLP SKG Real Estate Developers LLP</a
            >
            <br />
            <a
              class="links"
              href="https://SKG Real Estate Developers LLPSKG Real Estate Developers LLP.com/SKG Real Estate Developers LLP SKG Real Estate Developers LLP"
              target="_blank"
              >See more projects</a
            >
          </div>
          <div class="col col-sm-4 col-md-4 text-center link-container">
            <a class="links" href="about.html"
              >About SKG Real Estate Developers LLP SKG Real Estate Developers LLP</a
            >
            <br />
            <a class="links" href="terms-and-conditions.html"
              >Terms and Conditions</a
            >
          </div>
          <div class="col col-xs-12 col-sm-4 col-md-3 text-center">
            <a
              href="https://SKG Real Estate Developers LLP SKG Real Estate Developers LLP.com"
              target="_blank"
            >
              <img
                src="images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-logo-white.png"
                srcset="
                  images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-logo-white@2x.png 2x,
                  images/SKG Real Estate Developers LLP SKG Real Estate Developers LLP-logo-white@3x.png 3x
                "
                draggable="false"
              />
            </a>
          </div>
        </div>
        <div class="row text-center">
          <p class="copyright">
            SKG Real Estate Developers LLP- SKG Real Estate Developers LLP- all right reserved
          </p>
          <p class="copyright">
            SKG Real Estate Developers LLPis not a representative of SKG Real Estate Developers LLP SKG Real Estate Developers LLP
            After India. SKG Real Estate Developers LLP SKG Real Estate Developers LLP and the SKG Real Estate Developers LLP
            Indialogos are Trademarks of SKG Real Estate Developers LLPinc.
          </p>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default TermsPage
