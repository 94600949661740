import React from 'react'
import img  from '../assets/hero.jpg'
import { ReactTyped } from "react-typed";
import { Link } from 'react-scroll';


const Hero = () => {
    return (
        <div id='hero' className="relative h-screen bg-black">
            <img
                src={ img }
                alt="img"
                className="absolute inset-0 object-cover w-full h-full opacity-30"
            />
            <div className="absolute flex my-[220px]  w-full h-[100px] justify-center">
               <ReactTyped
            className="font-inter text-[#ecebeb] text-center content-center sm:text-[40px] md:text-[55px] lg:text-[90px] mb-4  font-black  drop-shadow-[0_5px_35px_rgba(250,250,250,0.25)]	"
            strings={["We Design." , "Build." , "And Inspire."]}
            typeSpeed={80}
            backSpeed={25}
            loop
            
            
            
          ></ReactTyped>
            </div>
            <div className="absolute w-full   md:my-[480px]  flex justify-center">
          <Link href="" to="about" spy={true} 
      smooth={true} 
      offset={-75} 
      duration={500}  className=" bg-transparent text-[#fdd26a] hover:text-[#ffffff] border-[0.2px] border-[#fdd26a] hover:border-[#fdfdfd] font-bold py-4 md:px-10 lg:px-8 rounded-xl">
            About Us
          </Link>
        </div>
        </div>
    )
}

export default Hero
