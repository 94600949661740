import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Team from "./components/Team";
import About from "./components/About";
import {Routes, Route} from 'react-router-dom';



function App() {
  return (
    <div >
  
         <Navbar/>
        <Hero/>
        <About/>
        <Services/>
        <Team/>
        <Contact/> 

        <Footer/>
   

        
        {/* <Route path="/" element={<Hero/>}/>
        <Route path="/privacy" element={<About/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/terms" element={<Terms/>}/>
      </Routes>
      
      <Footer/> */}
    </div>
  );
}

export default App;
