import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter,RouterProvider,Route } from 'react-router-dom';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';

const router = createBrowserRouter(
   [
    { path: '/', element: <App /> },
    { path: '/privacy', element: <PrivacyPage /> },
    { path: '/termsandconditions', element: <TermsPage /> },
  ],
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

