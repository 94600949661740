import React from 'react';
import aboutimg from '../assets/aboutimg.png';

const About = () => {
    return (
        <div id="about" className="bg-gray-100 py-32">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold mb-6 text-[#715b23]">About Us</h2>
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full md:w-1/2 px-4 mb-6">
                        <img src={aboutimg} alt="About Us" className="w-full h-auto rounded-lg" />
                    </div>
                    <div className="w-full md:w-1/2 px-4">
                        <p className="text-lg mb-4 mt-8">
                            With 20+ years of experience, SKG Builders is renowned for quality and timely
completion. We believe a home is where dreams come true, and our
commitment to excellence drives us to create inspiring spaces. Our team
ensures seamless integration of functionality and aesthetics. What sets us apart
is our unwavering dedication to on-time project delivery.


                        </p>
                        <p className="text-lg mb-4 mt-8">
                            We prioritize customer satisfaction, translating their needs into customized
solutions. Our open communication and transparent approach foster strong
relationships based on trust.
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
