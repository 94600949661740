import React from 'react'


const PrivacyPage = () => {
  return (
    <div id="privacy" className='text-white text-center'>
      
      <h1>SKG Real Estate Developers LLP Privacy Policy</h1>

    <h2>1. Information We Collect</h2>
    <p>
      We may collect the following types of information when you interact with
      us:
    </p>
    <ul>
      <li>
        <strong>Personal Information:</strong> This includes your name, email
        address, phone number, and any other information you voluntarily provide
        to us.
      </li>
      <li>
        <strong>Usage Data:</strong> Information about how you interact with our
        website, such as IP addresses, browser type, and browsing patterns.
      </li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
      <li>To provide and maintain our services.</li>
      <li>
        To communicate with you, including responding to your inquiries and
        providing updates.
      </li>
      <li>To improve our website and services.</li>
      <li>To comply with legal obligations.</li>
    </ul>

    <h2>3. Sharing of Information</h2>
    <p>
      We do not sell or rent your personal information to third parties.
      However, we may share your information with:
    </p>
    <ul>
      <li>Service providers who help us operate our business.</li>
      <li>
        Law enforcement agencies, government authorities, or other parties when
        required by law.
      </li>
    </ul>

    <h2>4. Security</h2>
    <p>
      We take reasonable measures to protect your personal information from
      unauthorized access, alteration, or disclosure. However, please note that
      no method of transmission over the internet is 100% secure, and we cannot
      guarantee the absolute security of your data.
    </p>

    <h2>5. Cookies</h2>
    <p>
      We may use cookies and similar technologies to enhance your experience on
      our website. You can manage your cookie preferences through your browser
      settings.
    </p>

    <h2>6. Third-Party Links</h2>
    <p>
      Our website may contain links to third-party websites. We are not
      responsible for the privacy practices or content of those websites. We
      encourage you to review the privacy policies of any third-party sites you
      visit.
    </p>

    <h2>7. Children’s Privacy</h2>
    <p>
      Our services are not intended for individuals under the age of 18. We do
      not knowingly collect personal information from children. If we become
      aware that a child has provided us with personal data, we will take steps
      to delete such information from our records.
    </p>

    <h2>8. Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time to reflect changes in
      our practices or applicable laws. We will notify you of any material
      changes by posting the updated policy on our website with a revised "Last
      Updated" date.
    </p>

    <h2>9. Contact Us</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy or our
      data practices, please contact us at:
    </p>
    <address>SKG Real Estate Developers LLP<br /></address>

    <p>
      By using our website or services, you consent to the collection and use of
      your information as described in this Privacy Policy.
    </p>
    
    </div>
  )
}

export default PrivacyPage
